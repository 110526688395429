var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.answers,"item-class":"spacing","hide-default-footer":"","no-data-text":"","dense":"","mobile-breakpoint":"800"},scopedSlots:_vm._u([{key:"header.sprite",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.headerSpriteTranslated))])]}},{key:"header.id",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.headerIDTranslated))])]}},{key:"header.types",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.headerTypesTranslated))])]}},{key:"header.color",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.headerColorTranslated))])]}},{key:"header.is_evolution",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.headerIsEvoTranslated))])]}},{key:"header.is_leg_or_myth",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.headerIsLegOrMythTranslated))])]}},{key:"item.sprite",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"src":item.sprite}})],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('ChipAnswerID',{attrs:{"guessID":_vm.pokemonToGuess.id,"answerID":item.id}})]}},{key:"item.types",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row",staticStyle:{"gap":"4px"}},_vm._l((item.types),function(type,i){return _c('div',{key:i},[_c('ChipAnswerType',{attrs:{"guessTypes":_vm.pokemonToGuess.types,"answerType":type}})],1)}),0)]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('ChipAnswerColor',{attrs:{"guessColor":_vm.pokemonToGuess.color,"answerColor":item.color}})]}},{key:"item.is_evolution",fn:function(ref){
var item = ref.item;
return [_c('ChipAnswerIsEvo',{attrs:{"guessIsEvo":_vm.pokemonToGuess.is_evolution,"answerIsEvo":item.is_evolution}})]}},{key:"item.is_leg_or_myth",fn:function(ref){
var item = ref.item;
return [_c('ChipAnswerIsLegOrMyth',{attrs:{"guessIsLegOrMyth":_vm.pokemonToGuess.is_leg_or_myth,"answerIsLegOrMyth":item.is_leg_or_myth}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }